import axios from 'axios';
import {
	API_ENDPOINTS,
	Card,
	CardBody,
	customToast,
	formatDate,
	PageLayout,
	route,
	StringHelpers,
	useLangContext,
	useStore,
	Variants,
	Icons,
	useDisabledContext,
	Button,
	ButtonTypes,
	Dropdown,
	SubTitle,
	Modal,
	replaceKeyWithValue,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QuizType } from '../../../types/quiz';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';

function Quiz() {
	const { quizId } = useParams();

	const { store, setValues } = useStore<QuizType>({} as QuizType);
	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);
	const navigate = useNavigate();
	const { crud, fields, models } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();

	const fetchQuizData = useCallback(async () => {
		try {
			const res = await axios.get<{ data: QuizType }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.QUIZ.VIEW, {
					trainingQuiz: String(quizId),
				})
			);
			setValues(res.data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	}, [quizId]);

	useEffect(() => {
		fetchQuizData();
	}, [quizId]);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	const archive = async () => {
		setDisabled(true);

		try {
			const { data } = await axios.delete(
				route(API_ENDPOINTS.ADMIN.TRAINING.QUIZ.DELETE, {
					trainingQuiz: String(quizId),
				})
			);
			customToast({
				title:
					data.message ||
					replaceKeyWithValue(
						'Success. Data for :model has been archived.',
						'model',
						models?.training_quiz || 'quiz(s)'
					),
				variant: Variants.Success,
			});
			await fetchQuizData();
			navigate(`/training/quiz/${quizId}`);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			actions.push({
				label: crud?.buttons.edit.default,
				onClick: () => navigate(`/training/quiz/${quizId}/edit`),
				variant: Variants.Dark,
				icon: Icons.EDIT,
				disabled: disabled,
			});
		}

		if (store?.permissions.delete) {
			actions.push({
				label: crud?.buttons.delete.default,
				onClick: () => setArchiveModalOpen(true),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled: disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view.title || 'View :model',
		'model',
		crud?.models?.training_quiz || 'Training Quiz'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<div className="mb-4 d-flex justify-content-between align-items-center">
					<Button
						label={StringHelpers.title(
							crud?.buttons.back.default || 'Back'
						)}
						onClick={() => navigate('/training/quiz')}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						disabled={disabled}
					/>
					{actions.length > 0 && (
						<Dropdown
							label={StringHelpers.title(
								crud?.buttons.actions.default || 'Actions'
							)}
							items={getActions()}
							type={ButtonTypes.Solid}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					)}
				</div>
				<Card>
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row m-0 p-0">
							<div className="col-12 col-lg-6 m-0 p-0 pe-lg-2">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.name ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.attempts_before_lockout ||
												'Attempts before lockout'
										)}
									</strong>
									<div>
										{store?.attempts_before_lockout || '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</strong>
									<div>{store?.description || '-'}</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 m-0 p-0 ps-lg-2">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.created_at || 'Created'
										)}
									</strong>
									<div>
										{store?.created_at
											? formatDate(store.created_at)
											: '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.updated_at || 'Updated'
										)}
									</strong>
									<div>
										{store?.updated_at
											? formatDate(store.updated_at)
											: '-'}
									</div>
								</div>
								{!!store?.deleted_at && (
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.archived_at ||
													'Archived'
											)}
										</strong>
										<div>
											{store?.deleted_at
												? formatDate(store.deleted_at)
												: '-'}
										</div>
									</div>
								)}
							</div>
						</div>
					</CardBody>
				</Card>

				<Card className="flex-lg-row flex-lg-row-reverse overflow-hidden">
					<CardBody>
						<h4
							className="mb-4"
							style={{ color: '#464C5E' }}
						>
							{StringHelpers.title(
								crud?.sub_titles?.questions || 'Questions'
							)}
						</h4>
						{store?.questions?.map((question, index) => (
							<Card>
								<CardBody className="pb-2 pt-3">
									<div className="mb-3">
										<strong>
											{StringHelpers.title(
												fields?.question +
													' ' +
													(index + 1) ||
													`Question ${index + 1}`
											)}
										</strong>
										<div>{question.question}</div>
									</div>
									<div className="mb-3">
										<strong>
											{StringHelpers.title(
												fields?.answer || 'Answer'
											)}
										</strong>
										<div>{question.answer}</div>
									</div>
									<div className="mb-0">
										<strong>
											{StringHelpers.title(
												fields?.options || 'Options'
											)}
										</strong>
										<div>
											<ul>
												{question?.options?.map(
													(option) => (
														<li>{option}</li>
													)
												)}
											</ul>
										</div>
									</div>
								</CardBody>
							</Card>
						))}
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<Modal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.delete.title || 'Delete :name?',
						'name',
						crud?.models?.training_quiz || 'Training Quiz'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.delete.submitting || 'Deleting...'
						: crud?.buttons.delete.default || 'Delete'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{crud?.modals?.delete.description.map(
					(item: string, key: number) => {
						return (
							<p key={key}>
								{replaceKeyWithValue(item, 'name', store?.name)}
							</p>
						);
					}
				)}
			</Modal>
		</PageLayout>
	);
}

export default Quiz;
