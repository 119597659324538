import './App.css';
import Providers from './Providers';
import Router from './Router';

function App() {
	return (
		<div className="App">
			<Providers>
				<Router />
			</Providers>
		</div>
	);
}

export default App;
