import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	ImageType,
	PageLayout,
	route,
	useLangContext,
	replaceKeyWithValue,
	StringHelpers,
	useAuthorisationContext,
} from 'carrier-fe';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { CourseType } from '../../types/course';

function CourseList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { fields, crud, models } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.training_course || 'Training Course'
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (
		action: string | ImageType | null,
		data: CourseType
	) => {
		const { id = '' } = data;

		const actions: Record<string, () => void> = {
			view: () => navigate(`/training/course/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const items = useMemo(() => {
		if (!hasPolicyAccess({name: 'training_course', method: 'create'})) return [];

		return [
			{
				label: StringHelpers.title(
					crud.buttons.create.default || 'Create'
				),
				disabled: false,
				href: '/training/course/create',
				icon: <Icon icon={Icons.ADD} />,
			},
		];
	}, []);

	const colMapping = (data: CourseType) => {
		const cols: any = [
			{
				id: 'name',
				label: StringHelpers.title(fields?.name || 'Name'),
				value: StringHelpers.title(data.name || '-'),
				sortable: true,
			},
			{
				id: 'category',
				label: StringHelpers.title(fields?.category || 'Category'),
				value: StringHelpers.title(data.category || '-'),
				sortable: true,
			},
			{
				id: 'created_at',
				label: StringHelpers.title(fields?.created || 'Created'),
				value: formatDate(data.created_at) || '-',
				sortable: true,
			},
			{
				id: 'deleted_at',
				label: StringHelpers.title(fields?.archived || 'Archived'),
				value: formatDate(data.deleted_at) || '-',
				sortable: true,
				visibleCondition: () => !!searchParams.get('filter[trashed]'),
			},
			{
				id: 'actions',
				label: StringHelpers.title(fields?.actions || 'Actions'),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || 'Action')}
						placeholder={StringHelpers.title(fields?.select || 'Select')}
						name="actions"
						value=""
						onChange={(value) => {
							if (!!value && typeof value === 'string') {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(
									crud?.view || 'View'
								),
								value: 'view',
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<PageLayout title={title || 'Training Courses'}>
			<DataTable<CourseType>
				routeEndpoint={route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.INDEX)}
				colMapping={colMapping}
				items={items}
				incrementPaginationButtons
				canViewArchived
			/>
		</PageLayout>
	);
}

export default CourseList;
