import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	Modal,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuestionType } from '../../../types/quiz';
import Questions from './Questions';

const defaultData = {
	name: '',
	attempts_before_lockout: '',
	description: '',
	questions: [
		{
			question: '',
			answer: '',
			options: [''],
			order: 0,
		},
	] as QuestionType[],
};

type Data = typeof defaultData;

function QuizForm() {
	const { quizId } = useParams();
	const isEdit = quizId ? true : false;

	const navigate = useNavigate();
	const { disabled } = useDisabledContext();
	const { crud, fields } = useLangContext();

	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (isEdit) fetchQuizData();
	}, [isEdit]);

	const fetchQuizData = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.QUIZ.VIEW, {
					trainingQuiz: String(quizId),
				})
			);
			setValues(data.data);
		} catch (error: any) {
			customToast({
				title:
					error?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const updateQuiz = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.QUIZ.UPDATE, {
						trainingQuiz: String(quizId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.QUIZ.STORE),
					payload
			  );
	};

	const {
		Input,
		Submit,
		errors,
		setErrors,
		store,
		setValues,
		data,
		isSuccess,
	} = useForm(updateQuiz, defaultData);

	useEffect(() => {
		if (isSuccess && data) {
			setValues(defaultData);
			const { id } = data;
			navigate(`/training/quiz/${id}`);
		}
	}, [isSuccess]);

	let title = replaceKeyWithValue(
		crud?.pages?.create.title || 'Create Training Quiz',
		'model',
		crud?.models?.training_quiz || 'Training Quiz'
	);

	if (isEdit) {
		title = replaceKeyWithValue(
			crud?.pages?.edit.title || 'Edit Training Quiz',
			'model',
			crud?.models?.training_quiz || 'Training Quiz'
		);
	}

	return (
		<>
			<PageLayout title={StringHelpers.title(title)}>
				<WidthConstrainedContainer>
					<Card>
						<CardBody className={'pb-0'}>
							<SubTitle
								title={StringHelpers.title(
									crud?.sub_titles?.details || 'Details'
								)}
								className="mb-3"
								style={{ color: '#464C5E' }}
							/>

							{Input({
								name: 'name',
								label: fields?.name || 'Name',
							})}

							{Input({
								name: 'attempts_before_lockout',
								label:
									fields?.attempts_before_lockout ||
									'Attempts before lockout',
								type: 'number',
							})}

							{Input({
								name: 'description',
								label: fields?.description || 'Description',
								type: 'textarea',
							})}
						</CardBody>
					</Card>

					<Questions
						store={store}
						setValues={setValues}
						errors={errors}
					/>
				</WidthConstrainedContainer>
				<BottomAnchoredControls>
					<Button
						label={crud?.buttons.back.default || 'Back'}
						onClick={() =>
							isEdit
								? navigate(`/training/quiz/${quizId}`)
								: navigate('/training/quiz')
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						className={'me-4'}
						disabled={disabled}
					/>
					<Submit
						defaultLabel={
							isEdit
								? crud?.buttons.edit.default || 'Update'
								: crud?.buttons.create.default || 'Create'
						}
						loadingLabel={
							isEdit
								? crud?.buttons.edit.submitting || 'Updating...'
								: crud?.buttons.create.submitting ||
								  'Creating...'
						}
					/>
				</BottomAnchoredControls>
			</PageLayout>
		</>
	);
}

export default QuizForm;
