import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	PageLayout,
	PostcodeLookup,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const defaultData = {
	name: '',
	enabled: '1',
	capacity: '',
	map_url: '',
};

type Data = typeof defaultData;

function VenueForm() {
	const { venueId } = useParams();
	const isEdit = venueId ? true : false;

	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (isEdit) {
			fetchVenueData();
		}
	}, []);

	const fetchVenueData = async () => {
		if (isFetching) return;

		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.VENUE.EDIT, {
					trainingVenue: String(venueId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const updateVenue = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.VENUE.UPDATE, {
						trainingVenue: String(venueId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.VENUE.STORE),
					payload
			  );
	};

	const { Input, Submit, errors, store, setValues, data } = useForm(
		updateVenue,
		defaultData
	);

	useEffect(() => {
		if (data) {
			navigate(`/training/venue/${data.id}`);
		}
	}, [data]);

	let title = replaceKeyWithValue(
		crud?.pages?.create.title || 'Create :model',
		'model',
		crud?.models?.training_venue || 'Training Venue'
	);

	if (isEdit) {
		title = replaceKeyWithValue(
			crud?.pages?.edit.title || 'Edit :model',
			'model',
			crud?.models?.training_venue || 'Training Venue'
		);
	}

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						{Input({
							name: 'name',
							label: fields?.name || 'Name',
						})}
						{Input({
							name: 'capacity',
							label: fields?.capacity || 'Capacity',
						})}
						{Input({
							name: 'map_url',
							label: fields?.map_url || 'Map URL',
						})}
						{Input({
							name: 'enabled',
							label: fields?.enabled || 'Enabled',
							type: 'select',
							options: crud?.options.boolean || [],
						})}
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<PostcodeLookup
							data={store}
							setData={setValues}
							errors={errors}
						/>
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.back.default || 'Back'}
					onClick={() =>
						isEdit
							? navigate(`/training/venue/${venueId}`)
							: navigate('/training/venue')
					}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={
						isEdit
							? crud?.buttons.edit.default || 'Update'
							: crud?.buttons.create.default || 'Create'
					}
					loadingLabel={
						isEdit
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.create.submitting || 'Creating...'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default VenueForm;
