import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	FormInput,
	ImageType,
	PageLayout,
	replaceKeyWithValue,
	route,
	SelectOption,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const defaultData = {
	name: '',
	description: '',
	training_course_id: '',
	event_at: '',
	training_venue_id: '',
	requires_payment: '0',
	price: '',
	currency: '',
};

type Data = typeof defaultData;

function EventForm() {
	const { eventId } = useParams();
	const isEdit = eventId ? true : false;

	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);

	const [courses, setCourses] = useState<SelectOption[]>();
	const [venues, setVenues] = useState<SelectOption[]>();
	const [currencies, setCurrencies] = useState<SelectOption[] | undefined>();

	useEffect(() => {
		fetchCourses();
		fetchVenues();
		fetchCurrencies();
	}, []);

	useEffect(() => {
		if (isEdit) {
			fetchEventData();
		}
	}, []);

	const fetchEventData = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.EDIT, {
					trainingEvent: String(eventId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const fetchCourses = () => {
		axios
			.get(route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.COURSES))
			.then((res) => setCourses(res.data.data));
	};

	const fetchVenues = () => {
		axios
			.get(route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.VENUES))
			.then((res) => setVenues(res.data.data));
	};

	const fetchCurrencies = () => {
		axios
			.get(route(API_ENDPOINTS.GENERIC.SELECT.CURRENCIES))
			.then((res) => setCurrencies(res.data.data));
	};

	const updateEvent = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.UPDATE, {
						trainingEvent: String(eventId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.STORE),
					payload
			  );
	};

	const { Input, Submit, store, setValues, isSuccess, data } = useForm(
		updateEvent,
		defaultData
	);

	useEffect(() => {
		if (isSuccess && data) {
			const { id } = data;
			navigate(`/training/event/${id}`);
		}
	}, [isSuccess]);

	let title = replaceKeyWithValue(
		crud?.pages?.create.title || 'Create :model',
		'model',
		crud?.models?.training_event || 'Training Event'
	);

	if (isEdit) {
		title = replaceKeyWithValue(
			crud?.pages?.edit.title || 'Edit :model',
			'model',
			crud?.models?.training_event || 'Training Event'
		);
	}

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>

						{Input({
							name: 'name',
							label: StringHelpers.title(fields?.name || 'Name'),
						})}

						{Input({
							name: 'description',
							label: StringHelpers.title(
								fields?.description || 'Description'
							),
							type: 'textarea',
						})}

						{Input({
							name: 'training_course_id',
							label: StringHelpers.title(
								fields?.training_course || 'Course'
							),
							type: 'searchable-select',
							placeholder: StringHelpers.title(
								replaceKeyWithValue(
									crud?.placeholders?.search ||
										'Search :model',
									'model',
									crud?.models?.course || 'course'
								)
							),
							options: courses,
						})}

						{Input({
							name: 'event_at',
							label: StringHelpers.title(
								fields?.event_at || 'Date'
							),
							type: 'datetime',
						})}

						{Input({
							name: 'training_venue_id',
							label: StringHelpers.title(
								fields?.training_venue || 'Venue'
							),
							type: 'searchable-select',
							placeholder: StringHelpers.title(
								replaceKeyWithValue(
									crud?.placeholders?.search ||
										'Search :model',
									'model',
									crud?.models?.venue || 'Venue'
								)
							),
							options: venues,
						})}
					</CardBody>
				</Card>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.payment || 'Payment'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>

						{Input({
							name: 'requires_payment',
							label: StringHelpers.title(
								fields?.requires_payment || 'Requires Payment'
							),
							type: 'select',
							options: crud?.options?.boolean || [],
						})}

						{store.requires_payment === '1' && (
							<>
								{Input({
									name: 'price',
									label: StringHelpers.title(
										fields?.price || 'Price'
									),
								})}

								{Input({
									name: 'currency',
									label: StringHelpers.title(
										fields?.currency || 'Currency'
									),
									type: 'select',
									options: currencies,
									placeholder: StringHelpers.title(
										fields?.please_select || 'Please select'
									),
								})}
							</>
						)}
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={StringHelpers.title(
						crud?.buttons.back.default || 'Back'
					)}
					onClick={() =>
						isEdit
							? navigate(`/training/event/${eventId}`)
							: navigate('/training/event')
					}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={
						isEdit
							? crud?.buttons.edit.default || 'Update'
							: crud?.buttons.create.default || 'Create'
					}
					loadingLabel={
						isEdit
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.create.submitting || 'Creating...'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default EventForm;
