import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	PageLayout,
	replaceKeyWithValue,
	route,
	SelectOption,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const defaultData = {
	name: '',
	training_category_id: '',
	description: '',
	image: '',
	course_expiry_years: '1',
	requires_in_person_event: '0',
};

type Data = typeof defaultData;

function CourseForm() {
	const { courseId } = useParams();
	const isEdit = courseId ? true : false;

	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);
	const [categories, setCategories] = useState<SelectOption[] | undefined>();

	useEffect(() => {
		fetchCategories();
	}, []);

	useEffect(() => {
		if (isEdit) {
			fetchCourseData();
		}
	}, []);

	const fetchCourseData = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.VIEW, {
					trainingCourse: String(courseId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const fetchCategories = () => {
		axios
			.get(route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.CATEGORIES))
			.then((res) => setCategories(res.data.data));
	};

	const updateCourse = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.UPDATE, {
						trainingCourse: String(courseId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STORE),
					payload
			  );
	};

	const { Input, Submit, setValues, data, errors } = useForm(
		updateCourse,
		defaultData
	);

	useEffect(() => {
		if (data) {
			navigate(`/training/course/${data.id}`);
		}
	}, [data]);

	const getImageErrors = () => {
		let imageErrors: any[] = [];

		if (!errors) {
			return null;
		}

		let keys = [
			'image.base64',
			'image.type',
			'image.size_bytes',
			'image.size_human',
			'image.name',
			'image.extension',
		];

		for (let i = 0; i < keys.length; i++) {
			if (!!errors && !!errors[keys[i]]) {
				if (typeof errors[keys[i]] === 'string') {
					imageErrors.push(errors[keys[i]]);
				} else if (Array.isArray(errors[keys[i]])) {
					imageErrors = [...imageErrors, ...errors[keys[i]]];
				}
			}
		}

		return imageErrors.length > 0 ? imageErrors : null;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.create.title || 'Create :model',
		'model',
		crud?.models?.training_course || 'Training Course'
	);

	if (isEdit) {
		title = replaceKeyWithValue(
			crud?.pages?.edit.title || 'Edit :model',
			'model',
			crud?.models?.training_course || 'Training Course'
		);
	}

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						{Input({
							name: 'name',
							label: fields?.name || 'Name',
						})}
						{Input({
							name: 'training_category_id',
							label:
								fields?.training_category_id ||
								'Training Category',
							type: 'select',
							options: categories,
							placeholder: StringHelpers.title(
								fields?.please_select || 'Please select'
							),
						})}
						{Input({
							name: 'description',
							label: fields?.description || 'Description',
							type: 'textarea',
						})}
						{Input({
							name: 'image',
							label: fields?.image || 'Image',
							type: 'single-image',
							errorMessages: getImageErrors(),
							description:
								fields?.suggested_dimensions ||
								'Suggested Dimensions: 500px x 500px',
						})}
						{Input({
							name: 'course_expiry_years',
							label:
								fields?.course_expiry_years ||
								'Course Expiry (Years)',
							type: 'number',
						})}
						{Input({
							name: 'requires_in_person_event',
							label:
								fields?.requires_in_person_event ||
								'Requires In Person Event',
							type: 'select',
							options: crud?.options.boolean || [],
						})}
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.back.default || 'Back'}
					onClick={() =>
						isEdit
							? navigate(`/training/course/${courseId}`)
							: navigate('/training/course')
					}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={
						isEdit
							? crud?.buttons.edit.default || 'Update'
							: crud?.buttons.create.default || 'Create'
					}
					loadingLabel={
						isEdit
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.create.submitting || 'Creating...'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default CourseForm;
