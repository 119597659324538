import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	Dropdown,
	formatDate,
	Icons,
	Modal,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	useStore,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CourseType } from '../../../types/course';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';

function Course() {
	const { courseId } = useParams();

	const { crud, fields, models } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();
	const navigate = useNavigate();

	const { store, setValues } = useStore<CourseType>({} as CourseType);

	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);

	useEffect(() => {
		fetchCourseData();
	}, [courseId]);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	const fetchCourseData = useCallback(async () => {
		try {
			const { data } = await axios.get<{ data: CourseType }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.VIEW, {
					trainingCourse: String(courseId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	}, [courseId]);

	const archive = async () => {
		setDisabled(true);

		try {
			const { data } = await axios.delete(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.DELETE, {
					trainingCourse: String(courseId),
				})
			);
			customToast({
				title:
					data.message ||
					replaceKeyWithValue(
						'Success. Data for :model has been archived.',
						'model',
						models?.training_course || 'course(s)'
					),
				variant: Variants.Success,
			});
			await fetchCourseData();
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.edit.default || 'Update'
				),
				onClick: () => navigate(`/training/course/${courseId}/edit`),
				variant: Variants.Dark,
				icon: Icons.EDIT,
				disabled: disabled,
			});
		}

		if (store?.permissions.delete) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.delete.default || 'Delete'
				),
				onClick: () => setArchiveModalOpen(true),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled: disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view.title || 'View :model',
		'model',
		crud?.models?.training_course || 'Training Course'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<div className="mb-4 d-flex justify-content-between align-items-center">
					<Button
						label={StringHelpers.title(
							crud?.buttons?.back?.default || 'Back'
						)}
						onClick={() => navigate('/training/course')}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						disabled={disabled}
					/>
					{actions.length > 0 && (
						<Dropdown
							label={StringHelpers.title(
								crud?.buttons?.actions?.default || 'Actions'
							)}
							items={getActions()}
							type={ButtonTypes.Solid}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					)}
				</div>
				<Card>
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row mb-4">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.name || '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</strong>
									<div>{store?.description || '-'}</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.requires_in_person_event ||
												'Required In Person Event'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											!!store &&
												store.requires_in_person_event ===
													'1'
												? fields?.yes
												: fields?.no
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.course_expiry_years ||
												'Course Expiry (Years)'
										)}
									</strong>
									<div>
										{store?.course_expiry_years || '-'}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.created_at || 'created'
										)}
									</strong>
									<div>
										{store?.created_at
											? formatDate(store.created_at)
											: '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.updated_at || 'updated'
										)}
									</strong>
									<div>
										{store?.updated_at
											? formatDate(store.updated_at)
											: '-'}
									</div>
								</div>
								{!!store?.deleted_at && (
									<div className="d-flex flex-column mb-3">
										<strong>
											{StringHelpers.title(
												fields?.archived_at ||
													'archived'
											)}
										</strong>
										<div>
											{store?.deleted_at
												? formatDate(store.deleted_at)
												: '-'}
										</div>
									</div>
								)}
							</div>
						</div>
					</CardBody>
				</Card>
			</WidthConstrainedContainer>

			<Modal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.delete.title || 'Delete :name?',
						'name',
						crud?.models?.training_course || 'Training Course'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.delete.submitting || 'Deleting...'
						: crud?.buttons.delete.default || 'Delete'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{!!store && (
					<>
						{crud?.modals?.delete.description.map(
							(item: string, key: number) => {
								return (
									<p key={key}>
										{replaceKeyWithValue(
											item,
											'name',
											store?.name
										)}
									</p>
								);
							}
						)}
						<p>
							{StringHelpers.title(fields?.training_category_id)}:{' '}
							{StringHelpers.title(
								store?.training_category?.name || ''
							)}
						</p>
						{/* TODO - Implement users */}
						<p>
							{StringHelpers.title(
								fields?.assigned_users + ' ' + 0
							)}
						</p>
					</>
				)}
			</Modal>
		</PageLayout>
	);
}

export default Course;
