import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	Dropdown,
	formatDate,
	Icons,
	Modal,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	useStore,
	Variants,
	WidthConstrainedContainer,
	FormInput,
	DateFormats,
} from 'carrier-fe';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventType } from '../../../types/event';
import EventInvites from './EventInvites';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import { isFuture, parse } from 'date-fns';

function Event() {
	const { eventId } = useParams();

	const { crud, fields, models } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();
	const navigate = useNavigate();

	const { store, setValues } = useStore<EventType>({} as EventType);

	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);

	const [refundIfPaid, setRefundIfPaid] = useState<boolean>(false);

	useEffect(() => {
		fetchEventData();
	}, [eventId]);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	const fetchEventData = useCallback(async () => {
		try {
			const { data } = await axios.get<{ data: EventType }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.VIEW, {
					trainingEvent: String(eventId),
				})
			);
			setValues(data.data);

			let eventAt = parse(data.data.event_at, DateFormats.DB, new Date());

			if (isFuture(eventAt)) {
				setRefundIfPaid(true);
			} else {
				setRefundIfPaid(false);
			}
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	}, [eventId]);

	const archive = async () => {
		setDisabled(true);

		try {
			const { data } = await axios.delete(
				route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.DELETE, {
					trainingEvent: String(eventId),
				}),
				{
					data: {
						refund_if_paid: refundIfPaid,
					},
				}
			);
			customToast({
				title:
					data.message ||
					replaceKeyWithValue(
						'Success. Data for :model has been archived.',
						'model',
						models?.training_event || 'event(s)'
					),
				variant: Variants.Success,
			});

			navigate('/training/event');
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			if (!store?.completed_at) {
				actions.push({
					label: StringHelpers.title(
						crud?.buttons.edit.default || 'Update'
					),
					onClick: () => navigate(`/training/event/${eventId}/edit`),
					variant: Variants.Dark,
					icon: Icons.EDIT,
					disabled: disabled,
				});
			}

			if (store?.can_be_completed) {
				actions.push({
					label: StringHelpers.title(
						crud?.buttons.complete.default || 'Complete'
					),
					onClick: () =>
						navigate(`/training/event/${eventId}/complete`),
					variant: Variants.Success,
					icon: Icons.CHECK_CIRCLE,
					disabled: disabled,
				});
			}
		}

		if (store?.permissions.delete) {
			actions.push({
				label: StringHelpers.title(
					crud?.buttons.archive.default || 'Archive'
				),
				onClick: () => setArchiveModalOpen(true),
				variant: Variants.Danger,
				icon: Icons.DELETE,
				disabled,
			});
		}

		return actions;
	};

	let title = replaceKeyWithValue(
		crud?.pages?.view?.title || 'View :model',
		'model',
		crud?.models?.training_event || 'Training Event'
	);

	let actions = getActions();

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<div className="mb-4 d-flex justify-content-between align-items-center">
					<Button
						label={StringHelpers.title(
							crud?.buttons?.back?.default || 'Back'
						)}
						onClick={() => navigate('/training/event')}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						disabled={disabled}
					/>
					{actions?.length > 0 && (
						<Dropdown
							label={StringHelpers.title(
								crud?.buttons?.actions?.default || 'Actions'
							)}
							items={actions}
							type={ButtonTypes.Solid}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					)}
				</div>
				<Card>
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row mb-4">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.name ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.description || 'Description'
										)}
									</strong>
									<div>{store?.description ?? '-'}</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.training_course_id ||
												'Course'
										)}
									</strong>
									<div className="d-flex flex-column">
										{store?.training_course?.permissions
											?.view ? (
											<a
												className={'text-dark'}
												href={`/training/course/${store?.training_course?.id}`}
												target={'_blank'}
											>
												{StringHelpers.title(
													store?.training_course
														?.name ?? '-'
												)}
											</a>
										) : (
											<>
												{StringHelpers.title(
													store?.training_course
														?.name ?? '-'
												)}
											</>
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.event_at || 'Date'
										)}
									</strong>
									<div>
										{store?.event_at
											? formatDate(store.event_at)
											: '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.completed_at || 'Completed'
										)}
									</strong>
									<div>
										{!!store && !!store.completed_at
											? 'Y'
											: 'N'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>Availability</strong>
									<div>{`${store?.availability_percentage}%`}</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.created_at || 'Created'
										)}
									</strong>
									<div>
										{store?.created_at
											? formatDate(store.created_at)
											: '-'}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.updated_at || 'Updated'
										)}
									</strong>
									<div>
										{store?.updated_at
											? formatDate(store.updated_at)
											: '-'}
									</div>
								</div>
							</div>
						</div>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.payment || 'Payment'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.requires_payment ||
												'Requires payment'
										)}
									</strong>
									<div>
										{store?.requires_payment === '1'
											? 'Y'
											: 'N'}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.price || 'Price'
										)}
									</strong>
									<div>{store?.display_price ?? '-'}</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<SubTitle
							title={StringHelpers.title(
								fields?.training_venue_id || 'Venue'
							)}
							className="mb-4 text-center"
							style={{ color: '#464C5E' }}
						/>
						<div className="row mb-4">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.name || 'Name'
										)}
									</strong>
									<div>
										{store?.training_venue?.permissions
											?.view ? (
											<a
												className={'text-dark'}
												href={`/training/venue/${store?.training_venue?.id}`}
												target={'_blank'}
											>
												{StringHelpers.title(
													store?.training_venue
														?.name ?? '-'
												)}
											</a>
										) : (
											<>
												{StringHelpers.title(
													store?.training_venue
														?.name ?? '-'
												)}
											</>
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.capacity || 'Capacity'
										)}
									</strong>
									<div>
										{store?.training_venue?.capacity ?? '-'}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div
									className="d-flex flex-column mb-3"
									style={{ overflow: 'hidden' }}
								>
									<strong>
										{StringHelpers.title(
											fields?.map_url || 'Map URL'
										)}
									</strong>
									<div>
										{store?.training_venue?.map_url ? (
											<a
												rel="noreferrer"
												href={
													store?.training_venue
														?.map_url
												}
												className={
													'btn btn-link btn-primary p-0'
												}
												target={'_blank'}
											>
												{store?.training_venue?.map_url}
											</a>
										) : (
											'-'
										)}
									</div>
								</div>
							</div>
						</div>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.address || 'Address'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<div className="row">
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_1 ||
												'address line 1'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.address_line_1 ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_2 ||
												'address line 2'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.address_line_2 ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.address_line_3 ||
												'address line 3'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.address_line_3 ?? '-'
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.town_city || 'town / city'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.town_city ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.state_county ||
												'state / county'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.state_county ?? '-'
										)}
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-6 col-xxl-4">
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.postcode_zipcode ||
												'postcode / zipcode'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.postcode_zipcode ?? '-'
										)}
									</div>
								</div>
								<div className="d-flex flex-column mb-3">
									<strong>
										{StringHelpers.title(
											fields?.country_code_iso_3 ||
												'country'
										)}
									</strong>
									<div>
										{StringHelpers.title(
											store?.training_venue?.address
												?.country ?? '-'
										)}
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
				<EventInvites store={store} />
			</WidthConstrainedContainer>

			<Modal
				open={archiveModalOpen}
				onClose={() => setArchiveModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.archive.title || 'Archive :name?',
						'name',
						crud?.models?.training_event || 'Training Event'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons?.archive?.submitting || 'Archiving...'
						: crud?.buttons?.archive?.default || 'Archive'
				}
				closeText={crud?.buttons?.cancel?.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{crud?.modals?.archive.description.map(
					(item: string, key: number) => {
						return (
							<p key={key}>
								{replaceKeyWithValue(item, 'name', store?.name)}
							</p>
						);
					}
				)}
				<p className="mb-2">
					<strong>
						{StringHelpers.title(
							fields?.description || 'Description'
						)}
						:
					</strong>
					<br />
					{store?.description}
				</p>
				<p className="mb-2">
					<strong>
						{StringHelpers.title(
							fields?.training_course || 'Training Course'
						)}
						:
					</strong>
					<br />
					{StringHelpers.title(store?.training_course?.name || '-')}
				</p>
				<p className="mb-4">
					<strong>
						{StringHelpers.title(fields?.event_at || 'Date')}:
					</strong>
					<br />
					{!!store ? formatDate(store?.event_at) : ''}
				</p>
				{store?.price_pence !== null && (
					<FormInput
						type={'select'}
						name={'refund_if_paid'}
						value={refundIfPaid ? '1' : '0'}
						onChange={(value) => setRefundIfPaid(value === '1')}
						options={crud?.options.boolean || []}
						disabled={disabled || archiving}
						label={StringHelpers.title(
							fields?.refund_if_paid || 'Refund If Paid'
						)}
					/>
				)}
			</Modal>
		</PageLayout>
	);
}

export default Event;
